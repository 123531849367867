<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600px"
  >
    <v-card class="pb-3">
      <v-card-title class="justify-center">
        <span class="headline">Modulo</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="moduleLocal.name"
              :readonly="show"
              label="Nome"
              outlined
              name="name"
              type="text"
              dense
              required
              hide-details
              :rules="requiredRules"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <treeselect
              v-model="moduleLocal.permissionsIds"
              :readonly="show"
              :multiple="true"
              :options="permissionsVal"
              placeholder="Permissões"
              value-consists-of="LEAF_PRIORITY"
              required
              :rules="requiredRules"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model.number="moduleLocal.userInterval"
              label="Int. utilizadores"
              type="number"
              :readonly="show"
              dense
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-currency-field
              v-model.number="moduleLocal.pricePerUser"
              label="Price Users"
              placeholder="500,000.00"
              :readonly="show"
              dense
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model.number="moduleLocal.profileInterval"
              label="Int. perfies"
              type="number"
              :readonly="show"
              dense
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-currency-field
              label="Price Candidates"
              v-model.number="moduleLocal.pricePerProfile"
              placeholder="500,000.00"
              :readonly="show"
              dense
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              label="Min. Profiles"
              v-model.number="moduleLocal.minimumProfiles"
              :readonly="show"
              dense
              outlined
              hide-details
            />
          </v-col>
          <v-col cols="12" md="12">
            <v-textarea
              v-model="moduleLocal.description"
              :readonly="show"
              outlined
              label="Descrição"
              rows="3"
              hide-details
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-row justify-end align-center mt-5 px-3">
          <v-btn
            color="primary"
            :outlined="!show"
            class="mx-2"
            @click="$emit('close')"
          >{{ show ? 'Fechar' : 'Cancelar'}}</v-btn>
          <v-btn
            v-if="!show"
            :disabled="isLoading"
            color="primary"
            @click="moduleLocal.id ? update() : save()"
          >Salvar</v-btn>
        </v-row>
      </v-card-text>
    </v-card>
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
  </v-dialog>
</template>

<script>
import { CREATE_MODULE_MUTATION, UPDATE_MODULE_MUTATION } from './../graphql/Mutation'
import { GET_PERMISSIONS_BY_TYPE_QUERY } from './../graphql/Query'
import { formatError } from '@/utils'
import { mapActions } from 'vuex'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  name: 'AddModuleDialog',
  components: { ErrorDialog, ProgressDialog, SuccessDialog, Treeselect },
  props: {
    dialog: Boolean,
    moduleLocal: Object,
    show: Boolean
  },
  data: () => ({
    error: undefined,
    isLoading: false,
    permissions: [],
    showError: false,
    showSuccess: false,
    success: undefined
  }),
  apollo: {
    permissions: {
      query: GET_PERMISSIONS_BY_TYPE_QUERY,
      variables: {
        type: 'entity'
      }
    }
  },
  computed: {
    permissionsVal () {
      return [
        {
          id: "dashboard",
          label: this.$t('dashboard'),
          children: [
            {
              id: this.getPermissionId("dashboard"),
              label: this.$t('dashboard'),
            },
            {
              id: this.getPermissionId("dashboard_activities"),
              label: this.$t('activities'),
            },
            {
              id: this.getPermissionId("dashboard_user_add"),
              label: this.$t('create_user'),
            },
            {
              id: this.getPermissionId("dashboard_client_add"),
              label: this.$t('create_customer'),
            },
            {
              id: this.getPermissionId("dashboard_candidate_add"),
              label: this.$t('create_candidate'),
            }
          ]
        },
        {
          id: "setting",
          label: this.$t('setting'),
          children: [
            // {
            //   id: this.getPermissionId("setting"),
            //   label: this.$t('setting'),
            // },
            {
              id: this.getPermissionId("setting_list"),
              label: this.$t('setting_list'),
            },
            {
              id: this.getPermissionId("setting_compony_profile_edit"),
              label: this.$t('institutionprofile'),
            },
            {
              id: this.getPermissionId("setting_access_level"),
              label: this.$t('AccessLevel'),
            },
            {
              id: this.getPermissionId("setting_user"),
              label: this.$t('user'),
            },
          ]
        },
        {
          id: "vacancies",
          label: this.$t('role_vacancy_label'),
          children: [
            {
              id: this.getPermissionId("vacancies_list"),
              label: this.$t('role_vacancy_list'),
            },
            { id: this.getPermissionId("publish"), label: "Publicar Vagas", isDisabled: this.show },
            {
              id: this.getPermissionId("vacancies_add"),
              label: this.$t('role_vacancy_add'),
            },
            {
              id: this.getPermissionId("vacancies_show_vacancy"),
              label: this.$t('role_vacancy_view'),
            },
            {
              id: this.getPermissionId("vacancies_edit_vacancy"),
              label: this.$t('role_vacancy_edit'),
            },
            {
              id: this.getPermissionId("vacancies_remove"),
              label: this.$t('role_vacancy_remov'),
            }
          ]
        },
        {
          id: "applications",
          label: this.$t('role_candidancy_label'),
          children: [
            {
              id: this.getPermissionId("applications_list"),
              label: this.$t('role_candidancy_list'),
            },
            {
              id: this.getPermissionId("send_test"),
              label: this.$t('role_candidancy_send_test'),
            },
            {
              id: this.getPermissionId("schedule_interview"),
              label: this.$t('role_candidancy_schedule'),
            }
          ]
        },
        {
          id: "ranking",
          label: "Ranking",
          children: [
            {
              id: this.getPermissionId("ranking_list"),
              label: "Ranking",
            },
            {
              id: this.getPermissionId("ranking_schedule_interview"),
              label: this.$t('role_ranking_schedule'),
            },
            {
              id: this.getPermissionId("ranking_show_profile"),
              label: this.$t('role_ranking_profile'),
            }
          ]
        },
        {
          id: "clients",
          label: this.$t('role_client_label'),
          children: [
            { 
              id: this.getPermissionId("clients_list"), 
              label: this.$t('role_client_list'), 
            },
            {
              id: this.getPermissionId("client_add"),
              label: this.$t('role_client_add'),
            },
            {
              id: this.getPermissionId("client_show"),
              label: this.$t('role_client_view'),
            },
            {
              id: this.getPermissionId("client_edit"),
              label: this.$t('role_client_edit'),
            },
            {
              id: this.getPermissionId("client_delete"),
              label: this.$t('role_client_remov'),
            }
          ]
        },
        {
          id: "database",
          label: this.$t('role_bd_label'),
          children: [
            {
              id: this.getPermissionId("database_list"),
              label: this.$t('role_bd_list'),
            },
            {
              id: this.getPermissionId("database_send_vacancy"),
              label: this.$t('role_bd_send_test'),
            }
          ]
        },
        {
          id: "interviews",
          label: this.$t('role_interview_label'),
          children: [
            {
              id: this.getPermissionId("interviews_list"),
              label: this.$t('role_interview_list'),
            }
          ]
        },
        {
          id: "tests",
          label: this.$t('role_test_label'),
          children: [
            { 
              id: this.getPermissionId("tests_list"), 
              label: this.$t('role_test_list'), 
            },
            {
              id: this.getPermissionId("tests_create"),
              label: this.$t('role_test_add'),
            },
            {
              id: this.getPermissionId("tests_preview"),
              label: this.$t('role_test_view'),
            },
            {
              id: this.getPermissionId("tests_update"),
              label: this.$t('role_test_edit'),
            },
            {
              id: this.getPermissionId("tests_delete"),
              label: this.$t('role_test_remov'),
            },
            {
              id: this.getPermissionId("tests_add_question"),
              label: this.$t('role_test_add_q'),
            }
          ]
        }
      ];
    },
    requiredRules () {
      return [v => !!v || "Campo obrigatório"]
    }
  },
  methods: {
    ...mapActions({
      setModule: 'moduleLocal/setModuleLocal',
      updateModuleState: 'moduleLocal/updateModuleLocal'
    }),
    getPermissionId (key) {
      // console.log("KEY: ", key);
      return this.permissions.length > 0 ? this.permissions.find(p => p.name == key).id : key
    },
    async save () {
      this.isLoading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_MODULE_MUTATION,
          variables: {
            moduleInput: this.moduleLocal
          }
        })
        this.setModule(data.createModule)
        this.success = 'Modulo criado com sucesso!'
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    async update () {
      this.isLoading = true
      try {
        const { data } = await this.$apollo.mutate({
          mutation: UPDATE_MODULE_MUTATION,
          variables: {
            id: this.moduleLocal.id,
            moduleInput: this.moduleLocal
          }
        })
        this.updateModuleState(data.updateModule)
        this.success = 'Modulo actualizado com sucesso!'
        this.showSuccess = true
        this.$emit('close')
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    }
  }
}

</script>

<style>
.v-input--selection-controls {
  margin-top: 0;
  margin-bottom: 0;
}

.vue-treeselect__control {
  border: 1px solid #9e9e9e;
  border-radius: 3px;
}

.vue-treeselect__placeholder {
  color: rgba(0, 0, 0, 0.6);
}

.vue-treeselect:not(.vue-treeselect--disabled):not(.vue-treeselect--focused)
  .vue-treeselect__control:hover {
  border: 1px solid #000;
}

</style>