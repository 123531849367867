import gql from 'graphql-tag'
import moduleFragment from './ModuleFragment'

export const DELETE_MODULE_MUTATION = gql`
  mutation DeleteModule($id: ID!) {
    deleteModule(id: $id) {
      id
    }
  }
`

export const CREATE_MODULE_MUTATION = gql`
  mutation CreateModule($moduleInput: ModuleInput) {
    createModule(moduleInput: $moduleInput) {
      ...${moduleFragment}
    }
  }
`
export const UPDATE_MODULE_MUTATION = gql`
  mutation UpdateModule($id: ID!, $moduleInput: ModuleInput) {
    updateModule(id: $id, moduleInput: $moduleInput) {
      ...${moduleFragment}
    }
  }
`