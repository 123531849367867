<template>
  <v-container class="fill-height pt-0" fluid>
    <v-row>
      <v-col cols="12" md="8">
        <div class="text-left subtitle">
          <strong>Modulo</strong>
        </div>
      </v-col>
      <v-col cols="12" md="4">
        <div class="text-right">
          <v-text-field
            append-icon="mdi-magnify"
            label="Pesquisar"
            single-line
            hide-details
            dense
            outlined
            rounded
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="py-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
      </v-col>
      <v-col cols="12" md="3">
        <v-btn
          rounded
          text
          outlined
          @click.prevent="dialog = !dialog"
        >
          <v-icon
            left
            color="#F7A400"
          >mdi-plus-circle-outline</v-icon>Novo Modulo
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="headers"
          :items="getModules"
          class="elevation-1"
          dense
        >
          <template v-slot:[`item.user`]="{ item }">
            {{ item.userInterval }} / {{ currencyFormatter(item.pricePerUser) }}
          </template>
          <template v-slot:[`item.profiles`]="{ item }">
            {{ item.profileInterval }} / {{ currencyFormatter(item.pricePerProfile) }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              x-small
              fab
              class="ma-1"
              @click="openShareDialog(item)"
            >
              <v-icon small>mdi-share-variant</v-icon>
            </v-btn>
            <v-btn
              color="blue"
              x-small
              fab
              class="ma-1"
              dark
              @click="showModule(item)"
            >
              <v-icon small>mdi-eye-outline</v-icon>
            </v-btn>
            <v-btn
              color="primary"
              x-small
              fab
              class="ma-1"
              dark
              @click="edit(item)"
            >
              <v-icon small>mdi-pencil</v-icon>
            </v-btn>
            <v-btn
              color="error"
              x-small
              fab
              class="ma-1"
              dark
              @click="openConfirmeDialog(item.id)"
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <add :dialog="dialog" :moduleLocal="moduleLocal" :show="show" @close="closeAddDialog()" />
    <confirm-dialog 
      :dialog="confirm" 
      :id="removeId" 
      question="Pretende remover o Modulo?" 
      @yes="deleteModule" 
      @no="confirm = false" 
    />
    <error-dialog :dialog="showError" :message="error" @close="showError = false" />
    <progress-dialog :processing="isLoading" />
    <success-dialog :dialog="showSuccess" :message="success" @close="showSuccess = false" />
    <share-dialog />
  </v-container>
</template>

<script>
import { MODULES_QUERY } from './../graphql/Query'
import { DELETE_MODULE_MUTATION } from './../graphql/Mutation'
import { mapActions, mapGetters } from 'vuex'
import { formatError } from '@/utils'
// import formatCurrencyMixins from "@/mixins/format-currency";
import Module from '@/models/Module'
import Add from './../components/AddModuleDialog'
import ConfirmDialog from './../../../components/ConfirmDialog.vue'
import ErrorDialog from './../../../components/ErrorDialog.vue'
import ProgressDialog from './../../../components/ProgressDialog.vue'
import SuccessDialog from './../../../components/SuccessDialog.vue'
import ShareDialog from "./../components/ShareDialog.vue";
import dialogMixins from '@/mixins/dialog'
export default {
  name: 'Module',
  mixins: [
    dialogMixins,
    // formatCurrencyMixins
  ],
  components: {
    Add, 
    ConfirmDialog, 
    ErrorDialog, 
    ProgressDialog, 
    SuccessDialog,
    ShareDialog,
  },
  data: () => ({
    alert: true,
    modules: [],
    moduleLocal: new Module(),
  }),
  apollo: {
    modules: {
      query: MODULES_QUERY
    }
  },
  watch: {
    modules: function (val) {
      this.setModules(val)
    }
  },
  computed: {
    ...mapGetters({
      getModules: 'moduleLocal/getModuleLocals'
    }),
    headers () {
      return [
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "name"
        },
        {
          text: this.$t('users'),
          align: "center",
          sortable: false,
          value: "user"
        },
        {
          text: this.$t('profiles'),
          align: "center",
          sortable: false,
          value: "profiles"
        },
        {
          text: "Min. Profiles",
          align: "end",
          sortable: false,
          value: "minimumProfiles"
        },
        {
          text: "Descrição",
          align: "start",
          sortable: false,
          value: "description"
        },
        {
          text: "Status",
          align: "start",
          sortable: false,
          value: "status"
        },
        { text: "Acção", align: "center", value: "actions" }
      ];
    }
  },
  methods: {
    ...mapActions({
      removeModule: 'moduleLocal/removeModuleLocal',
      setModules: 'moduleLocal/setModuleLocals',
    }),
    closeAddDialog () {
      this.moduleLocal = new Module()
      this.show = false
      this.dialog = false
    },
    currencyFormatter (price) {
      const locale = 'en-US';
      const currency = 'MZN';
      let mtnLocale = Intl.NumberFormat(locale, {
        style: 'currency',
        currency
      });
      return mtnLocale.format(price);
    },
    openShareDialog(moduleLocal) {
      console.log(moduleLocal)
      // eslint-disable-next-line no-undef
      Fire.$emit("shareModule", moduleLocal.id);
    },
    async deleteModule (id) {
      this.confirm = false
      this.isLoading = true
      try {
        await this.$apollo.mutate({
          mutation: DELETE_MODULE_MUTATION,
          variables: {
            id
          }
        })
        this.removeModule(id)
        this.success = 'Nivel de acesso removido com sucesso!'
        this.showSuccess = true
      } catch (error) {
        this.error = formatError(error.message)
        this.showError = true
      } finally {
        this.isLoading = false
      }
    },
    edit (moduleLocal) {
      const { id, description, minimumProfiles, name, permissions, pricePerProfile, pricePerUser, profileInterval, userInterval } = moduleLocal
      const permissionsIds = permissions.map(p => (p.id))
      this.moduleLocal = { id, description, minimumProfiles, name, permissionsIds, pricePerProfile, pricePerUser, profileInterval, userInterval }
      this.dialog = true
    },
    openConfirmeDialog (id) {
      this.removeId = id
      this.confirm = true
    },
    showModule (moduleLocal) {
      const { id, description, minimumProfiles, name, permissions, pricePerProfile, pricePerUser, profileInterval, userInterval } = moduleLocal
      const permissionsIds = permissions.map(p => (p.id))
      this.moduleLocal = { id, description, minimumProfiles, name, permissionsIds, pricePerProfile, pricePerUser, profileInterval, userInterval }
      this.show = true
      this.dialog = true
    }
  }
}
</script>