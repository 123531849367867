import gql from 'graphql-tag'
import moduleFragment from './ModuleFragment'

export const GET_PERMISSIONS_BY_TYPE_QUERY = gql`
  query GetPermissionsByType($type: String!) {
    permissions: getPermissionsByType(type: $type) {
      id
      description
      name
      status
      type
    }
  }
`

export const MODULES_QUERY = gql`
  query Modules {
    modules: modules {
      ...${moduleFragment}
    }
  }
`